div {
    &[data-baseweb="card"] {
        &.flex-column {
            > div {
                display: flex;
                flex-direction: column;
                flex-grow: 1;

                > div {
                    flex-grow: 1;
                    margin-bottom: 0;
                    display: flex;
                    flex-direction: column;

                    div {
                        &.flex-row {
                            height: 100%;
                            display: flex;
                            flex-direction: row;
                            flex-grow: 1;
                            justify-content: space-around;
                            align-items: center;

                            .circle {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                font-size: xx-large;
                                font-weight: bold;
                                color: $color-white;
                            }
                        }
                    }
                }
            }
        }

        h1 {
            div {
                &[data-baseweb="select"] {
                    margin-left: 5px;
                    div {
                        background-color: $color-white;
                    }
                }
            }
        }
    }
}

.data-subtitle {
    margin-top: 20px;
    font-size: larger;
}

.chart-overlay {
    z-index: 100;
    position: absolute;
}
