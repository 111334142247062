.fixed-height {
    height: 370px;
}

.card-label {
    p {
        &[data-baseweb="typo-p3"] {
            font-weight: bolder;
        }
    }
}

.map-component {
    margin-left: auto;
    height: 286px;
    width: 100%;
    z-index: 5;
}

.map-component-placeholder {
    background-color: $oop-grey;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 286px;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

.blink {
    animation: blinker 2s step-start infinite;
}
