@import "toastr";

@import "variables";

@import "layouts/header";
@import "layouts/side-navigation";
@import "layouts/login";

html {
    background-color: $oop-grey;
}

body {
    color: $oop-dark-blue;
    font-family: $body-font-family;
    font-size: 12px;
    font-weight: normal;
    margin: 0;
    background-color: $oop-grey;
}

div[id="root"] {
    position: relative;

    .content {
        background-color: $oop-grey;
        flex: 1;
        margin-left: 9.5rem;
        padding: 9em 2rem 2rem;

        .content-wrapper {
            background-color: $color-white;
            padding: 1.5rem;
        }

        @media screen and (max-width: 650px) {
            padding: 26vh 0.5rem 0.5rem;
            margin-left: unset;

            .content-wrapper {
                padding: 1vmin;
            }

            .page-heading {
                font-size: 8vmin;
            }

            .action-header {
                flex-direction: column;
            }
        }
    }
}

a {
    color: $color-black;
    text-decoration: none;

    &:hover {
        color: $oop-teal;
    }

    a:active,
    a:visited {
        color: inherit;
    }
}

.title {
    margin-bottom: 1rem;
}

@import "layouts/loading-overlay";
@import "layouts/site-selector";
@import "layouts/device_temprs";
@import "layouts/device_dashboard";
@import "layouts/table";
@import "layouts/dashboard";
@import "layouts/tempr";
