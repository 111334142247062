div {
    &[role="grid"] {
        overflow: auto;
    }
    &[role="rowgroup"] {
    }
    &[role="row"] {
        &:nth-child(even) {
            background-color: $oop-light-grey;
        }
        &:nth-child(odd) {
            background-color: $oop-lighter-grey;
        }
    }

    &[role="columnheader"] {
        background-color: $color-white;
    }
}
