.device-tempr {
    cursor: pointer;

    div {
        &.action-column {
            cursor: auto;
        }
    }

    &.selected {
        div {
            font-weight: bolder;
        }
    }
}

.schedule-tempr {
    cursor: pointer;

    div {
        &.action-column {
            cursor: auto;
        }
    }

    &.selected {
        div {
            font-weight: bolder;
        }
    }
}


.tempr-preview {
    margin: 1rem 0;
    max-height: 500px;
    min-height: 100px;
    display: flex;

    div {
        &[data-baseweb="tabs"] {
            width: 100%;

            div {
                &[role="tabpanel"] {
                    overflow: auto;
                    padding-left: 10px;
                    padding-right: 10px;
                }
            }
        }
    }
}

.tempr-preview-content {
    background-color: $color-white;
    overflow-y: auto;
    padding-left: 10px;
    padding-right: 10px;
}
