.site-selector {
    min-width: 12rem;
}

.selected-site {
    color: white;
}

.icon {
    cursor: pointer;
    color: white;
}