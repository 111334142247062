.toggle {
    margin: 0 1rem;

    input {
        &[type="checkbox"] {
            display: none;
        }
    }
    > label {
        > div {
            padding-right: 0;
            margin-bottom: 0;

            > div {
                background-color: $oop-teal;
            }
        }
    }
}
div[data-baseweb="popover"] {
    z-index: 300 !important;
}
